import React from "react";
import { FaArrowDown } from "react-icons/fa"

function SwitchPageButton({refFn}) {
  return (
    <div className="switch-page-container">
      <div className="switch-page-button" onClick={refFn}>
        <FaArrowDown />
      </div>
    </div>
  )
}

export default SwitchPageButton;
