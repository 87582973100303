import React from "react"
import { ParallaxLayer } from "react-spring/renderprops-addons"

import { FaExternalLinkAlt } from "react-icons/fa"

const ParallaxPage = ({
  pageIndex,
  dark,
  linkText,
  linkUrl,
  title,
  description,
  children,
}) => (
  <>
    <ParallaxLayer
      offset={pageIndex}
      speed={0.4}
      className={dark ? "bg-dark" : "bg-light"}
    />
    <ParallaxLayer offset={pageIndex} speed={0.5} className="layer layer-title">
      <h2 className={dark ? "dark" : null}>{title}</h2>
    </ParallaxLayer>
    <ParallaxLayer
      offset={pageIndex}
      speed={0.7}
      className="layer layer-description"
    >
      <div className={dark ? "dark" : null}>{description}</div>
    </ParallaxLayer>
    <ParallaxLayer
      offset={pageIndex}
      speed={0.2}
      className="layer layer-link"
    >
      <h3 className={dark ? "dark" : null}>
        {linkUrl ? (
          <a href={linkUrl}>
            {linkText}
            <div className="icon">
              <FaExternalLinkAlt />
            </div>
          </a>
        ) : (
          linkText
        )}
      </h3>
    </ParallaxLayer>
    {children}
  </>
)

export default ParallaxPage
