import React from "react"
import {Parallax, ParallaxLayer} from 'react-spring/renderprops-addons'
import PuffLoader from "react-spinners/PuffLoader"

import Image from "../components/image"
import SEO from "../components/seo"
import ParallaxPage from "../components/parallax-page"
import HomeVideo from "../components/home-video"
import SwitchPageButton from "../components/switch-page-button"

import { FaExternalLinkAlt } from 'react-icons/fa';


class IndexPage extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      nbPages: 6,
    }
  }

  handleClick = () => {
    var curIndex = Math.floor((this.parallax.current + 100) / this.parallax.space);

    const { nbPages } = this.state;
    let newIndex = curIndex + 1;
    if (curIndex >= (nbPages - 1)) {
      newIndex = 0;
    }
    this.parallax.scrollTo(newIndex)
  }

  videoLoaded = () => {
    console.log("video loaded!");
  }

  videoEnded = () => {
    console.log("video ended");
  }

  render() {
    const { nbPages } = this.state;

    return (
      <div className="landing-page">
        <SEO title="Home"></SEO>

        <SwitchPageButton refFn={() => this.handleClick(2)} />

        <Parallax ref={ref => (this.parallax = ref)} pages={nbPages}>
          <ParallaxLayer offset={2} speed={0.4} style={{ backgroundColor: '#000' }} />
          <ParallaxLayer offset={3} speed={0.4} style={{ backgroundColor: '#000' }} />


          <ParallaxPage
            pageIndex={0}
            dark
            linkText="alexisgj.com"
            title="Software Development"
          >
            <ParallaxLayer
              offset={0}
              speed={0.1}
              className="layer-bg-container">
              <div className="video-container">
                <div className="video-loading">
                  <PuffLoader color="#fff" />
                </div>
                <HomeVideo _videoLoadedCallback={this.videoLoaded} />
              </div>
            </ParallaxLayer>
          </ParallaxPage>


          <ParallaxPage
            pageIndex={1}
            linkText="patinoires.ca"
            linkUrl="https://patinoires.ca"
            title="Real-time ice rinks status"
            description="Developed this website to display live conditions of ice rinks on the island of Montreal. The website uses open data from the City of Montreal. Plans for the future include gathering conditions from users and expanding ice rinks for the whole province of Quebec."
          >
            <ParallaxLayer
              offset={1}
              speed={1}
              className="layer-bg-container">
              <div className="layer-bg-img">
                <Image imgName="patinoires_06.png" />
              </div>
            </ParallaxLayer>
          </ParallaxPage>


          <ParallaxPage
            pageIndex={2}
            dark
            linkText="McGill Articial Pancreas Lab"
            title="Monitoring System"
            description="For our final project at McGill University, we (team of 3) built a diabetes monitoring system for Camp Carowanis, a type 1 diabetics summer camp. Our goal was to prevent and ameliorate the lives of both the children and the nurses at the camp. The outcome of the project turned out great, here is a video (in French) explaining the impact it made during the summer 2019:"
          >
            <ParallaxLayer
              offset={2}
              speed={1}
              className="layer-bg-container layer-bg-bottom">
              <div className="layer-bg-img">
                <Image imgName="carowanis_05.png" bottomLeft/>
              </div>
            </ParallaxLayer>
          </ParallaxPage>


          <ParallaxPage
            pageIndex={3}
            linkText="vergerjoannette.ca"
            linkUrl="https://vergerjoannette.ca"
            title="A Family Apple Orchard"
            description="Verger Joannette Bio is an organic apple orchard in Quebec, Canada. From an HTML, CSS, Javascript website and custom multilingual generation from a PHP code, now a new website made using React and hosted CMS."
          >
            <ParallaxLayer
              offset={3}
              speed={1}
              className="layer-bg-container layer-bg-bottom">
              <div className="layer-bg-img">
                <Image imgName="vj_02.png" bottomLeft/>
              </div>
            </ParallaxLayer>
          </ParallaxPage>


          <ParallaxPage
            pageIndex={4}
            dark
            linkText="WeTrack Music"
            title="Collaborative Playlists"
            description="Developed an iOS application and web platform (mobile friendly) that uses the YouTube API to create a collaborative playlist for everyone to add their songs.
              Developed a remote for the iOS application to control the playback of the playlist (play, pause, skip) using PHP, MySQL to control actions."
          >
            <ParallaxLayer
              offset={4}
              speed={1}
              className="layer-bg-container">
              <div className="layer-bg-img layer-bg-larger">
                <Image imgName="wetrackmusic_04.png"/>
              </div>
            </ParallaxLayer>
          </ParallaxPage>


          <ParallaxLayer
            offset={5}
            speed={0.4}
            className="bg-light"
          />
          <ParallaxLayer offset={5} speed={0.5} className="layer footer-title">
            <h2>Contact Me</h2>
          </ParallaxLayer>
          <ParallaxLayer
            offset={5}
            speed={0.7}
            className="layer"
            style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="footer-description">Thank you for coming by. Hit me up, I'm always open to new opportunities.</div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={5}
            speed={1}
            className="footer-credits"
          >
            <div>2020 &copy; alexisgj.com</div>
          </ParallaxLayer>
          <ParallaxLayer
            offset={5}
            speed={0.2}
            className="layer footer-link"
          >
            <h3>
              <a href="mailto:info@alexisgj.com">
                info@alexisgj.com
                <div className="icon">
                  <FaExternalLinkAlt />
                </div>
              </a>
            </h3>
          </ParallaxLayer>


        </Parallax>
      </div>
    )
  }
}

export default IndexPage
