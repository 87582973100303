import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Image = ({ imgName, bottomLeft }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 2500) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === imgName
      )
      if (!image) {
        return null
      }
      return <Img 
        fluid={image.node.fluid}
        objectFit="contain"
        objectPosition={bottomLeft ? "100% 100%" : "50% 50%"}
        style={{ position: 'inherit', width: '100%', height: '100%' }}
      />
    }}
  />
)

export default Image
